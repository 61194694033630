
import {
  defineJQueryPlugin,
  emulateTransitionEnd,
  getElementFromSelector,
  getTransitionDurationFromElement,
  reflow
} from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'
import Tab from './tab'
import api from './api'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'tabcontainer'
const DATA_KEY = 'ff.tabcontainer'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_HIDE = `hide${EVENT_KEY}`
const EVENT_HIDDEN = `hidden${EVENT_KEY}`
const EVENT_SHOW = `show${EVENT_KEY}`
const EVENT_SHOWN = `shown${EVENT_KEY}`
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="tabcontainer"]'
const SELECTOR_NAV_LIST_GROUP = '#contentleftdishrecipe'
const SELECTOR_TAB_GROUP = 'dish_content'
// const SELECTOR_NAV_LIST_GROUP_ACTIVE = '.nav .active, .list-group .active'
const SELECTOR_ACTIVE = '.active'
const SELECTOR_ACTIVE_UL = '> li > .active'

const CLASS_NAME_ACTIVE = 'active'
const CLASS_NAME_DISABLED = 'disabled'
const CLASS_NAME_FADE = 'fade'
const CLASS_NAME_SHOW = 'show'

class TabContainer extends Tab {
  constructor(element, conf) {
    super(element)
    this._element = element
    this.buttons = null
    this.content = null
    this.tabs = []
    this.current = null
    this.css = {
      active: 'selected',
      container: 'tabcontent'
    }
    Object.assign(this, conf)
    Data.setData(element, this.constructor.DATA_KEY, this)
  }

  add(item) {
    this.tabs.push(item)
  }

  show() {
    if ((this._element.parentNode &&
            this._element.parentNode.nodeType === Node.ELEMENT_NODE &&
            this._element.classList.contains(CLASS_NAME_ACTIVE)) ||
            this._element.classList.contains(CLASS_NAME_DISABLED)) {
      return
    }

    for (const el of [window.ff.el.productContent, window.ff.el.dishList]) {
      el.hide()
    }

    document.getElementById(SELECTOR_TAB_GROUP).show()
    let previous
    let target = getElementFromSelector(this._element)
    for (const el of document.querySelectorAll('#' + SELECTOR_TAB_GROUP + ' > div')) {
      el.hide()
    }

    if (!target && this._element.dataset.hasOwnProperty('ffData')) {
      const newDiv = document.createElement('div')
      newDiv.id = this._element.getAttribute('data-ff-target')
      target = newDiv
      document.getElementById(SELECTOR_TAB_GROUP).append(newDiv)
      const ajaxJson = JSON.parse(this._element.getAttribute('data-ff-data'))
      api.post(
        ff.pages.ajax,
        ajaxJson,
        true
      ).then(data => {
        newDiv.setInnerHTML(data)
      })
    }

    const listElement = this._element.closest(SELECTOR_NAV_LIST_GROUP)

    if (listElement) {
      const itemSelector = listElement.nodeName === 'UL' || listElement.nodeName === 'OL' ? SELECTOR_ACTIVE_UL : SELECTOR_ACTIVE
      previous = SelectorEngine.find(itemSelector, listElement)
      previous = previous[previous.length - 1]
    }

    const hideEvent = previous ?
      EventHandler.trigger(previous, EVENT_HIDE, {
        relatedTarget: this._element
      }) :
      null

    const showEvent = EventHandler.trigger(this._element, EVENT_SHOW, {
      relatedTarget: previous
    })

    if (showEvent.defaultPrevented || (hideEvent !== null && hideEvent.defaultPrevented)) {
      return
    }

    document.getElementById(SELECTOR_TAB_GROUP).show()

    this._activate(this._element, listElement)

    const complete = () => {
      EventHandler.trigger(previous, EVENT_HIDDEN, {
        relatedTarget: this._element
      })
      EventHandler.trigger(this._element, EVENT_SHOWN, {
        relatedTarget: previous
      })
    }

    if (target) {
      this._activate(target, target.parentNode, complete)
    } else {
      complete()
    }
  }

  static deactivate(item) {
    item = item || this.current
    if (item) {
      item._deactivate()
    }
  }

  get_active() {
    return this.current
  }

  static jQueryInterface(config) {
    return this.each(function () {
      const data = Data.getData(this, DATA_KEY) || new TabContainer(this)
      if (typeof config === 'string') {
        if (typeof data[config] === 'undefined') {
          throw new TypeError(`No method named "${config}"`)
        }

        data[config]()
      }
    })
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  const trgt = event.target

  if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
    event.preventDefault()
  }

  const data = Data.getData(this, DATA_KEY) || new TabContainer(this)
  data.show()
})
/*
EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
    if (document.querySelector(SELECTOR_NAV_LIST_GROUP_ACTIVE)){
        const data = Data.getData(document.querySelector(SELECTOR_NAV_LIST_GROUP_ACTIVE), DATA_KEY) ||
            new TabContainer(document.querySelector(SELECTOR_NAV_LIST_GROUP_ACTIVE))
        data.show()
    }
})
*/
/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Tab to jQuery only if jQuery is present
 */

defineJQueryPlugin(NAME, TabContainer)

export default TabContainer
