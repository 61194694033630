
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'
import { isMobile } from './util'

const NAME = 'needs'
const DATA_KEY = 'ff.needs'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="needs"]'

class Needs {
  constructor() {
    window.ff.currentNeedsQ = 1
    this.close = true
    this.questionContainer = null
    this.currentItem = null
    this.currentTab = null
    this.answerContainer = null
    this.itemContainer = null
    this._dialog = {}
  }

  static openTab(tab) {
    /*
    if (isMobile) {
      const itemsCrs = this.questionContainer.querySelectorAll('div.items > ul li')
      for (const el of itemsCrs) {
        el.hide()
      }
    }
    */
    if (this.currentTab) {
      let pid = ''

      pid = Object.prototype.hasOwnProperty.call('id', tab) ? tab.id : tab

      if (pid !== this.currentTab.id) {
        this.questionContainer.querySelector('div.answers #' + this.currentTab.id).hide()
      }

      /*
      if (!this.currentItem) {
        this.currentItem = this.questionContainer.querySelector('div.items .selected')
      }

      this.currentItem.classList.remove('selected')
      */
      this.currentTab.hide()
    }

    if (!tab.id) {
      tab = this.questionContainer.querySelector('div.answers #' + tab)
    }
    tab.show()
    this.currentItem = this.questionContainer.querySelector('div.items #' + tab.id)
    if (this.currentItem !== null) {
      const prevSel = this.questionContainer.querySelectorAll('div.items li.selected')
      for (const el of prevSel) {
        el.classList.remove('selected')
      }

      this.currentItem.classList.add('selected')
      if (isMobile) {
        if (this.currentItem.tagName.toLowerCase() === 'li') {
          const itemCrs = SelectorEngine.parents(this.currentItem, 'li')
          for (const el of itemCrs) {
            el.show()
          }

          const ansPanel = document.querySelector('.answerpanel #' + this.currentItem.id)

          if (ansPanel) {
            ansPanel.show()
          }
          this.currentItem.show()
        } else {
          const itemCrs = SelectorEngine.parents(this.currentItem, 'li')
          itemCrs[0].show()
        }
      }
    }

    this.currentTab = tab
    setTimeout(() => {
      if (this.currentTab && this.currentTab.querySelectorAll('input').length) {
        this.currentTab.querySelector('input').focus()
      }
    }, 150)
  }

  static previousTab() {
    if (this.currentTab && this.currentTab.previousElementSibling) {
      this.openTab(this.currentTab.previousElementSibling.id)
    }
  }

  static nextTab() {
    if (this.currentTab && this.currentTab.nextElementSibling) {
      this.openTab(this.currentTab.nextElementSibling.id)
    } else if (this.currentTab.querySelectorAll('input[type=radio]:checked, input[type=checkbox]:checked').length === 0) {
      const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
      const form = currentDisplay.closest('form')
      Dialog.alert(form, { content: lang.diary_js_invalid_need_question })
      return false
    }
  }

  static hasattr(el, attr) {
    return typeof el === 'object' && el !== null && 'getAttribute' in el  && el.hasAttribute(attr) ? true : false
  }

  static resetTabs() {
    // into tab 2 of needs or later
    let uncompleted = {}
    this.questionContainer = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
    this.answerContainer = this.questionContainer.querySelectorAll('.answerpanel > .answers .answers')
    for (const el of this.answerContainer) {
      if (el.querySelectorAll('input[type=radio]:checked, input[type=checkbox]:checked').length === 0 && !uncompleted.nodeType) {
        uncompleted = el
      } else {
        el.hide()
      }
    }

    this.itemContainer = this.questionContainer.querySelector('div.items')
    /*
    if (isMobile) {
      const itemsCrs = this.questionContainer.querySelectorAll('div.items > ul > li')
      for (const el of itemsCrs) {
        el.hide()
      }
    }
    */
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
    const currentAnswer = currentDisplay.querySelector('div.answers:not(.hidden)')

    const completed = this.questionContainer.querySelectorAll('div.items .products .completed')
    uncompleted = this.questionContainer.querySelector('div.items li[id*=\'product\']:not(.completed)')
    if (completed.length > 0 && !isMobile) {
      const lastCompleted = completed[completed.length - 1]
      this.openTab(lastCompleted.id)
      this.nextTab()
    } else if (uncompleted && this.hasattr(uncompleted, 'id')) {
      this.openTab(uncompleted.id)
    } else if (completed.length > 0 && !this.currentItem && isMobile) {
      this.openTab(completed[0].id)
    } else if (completed.length > 0 && this.currentItem && this.currentTab.nextElementSibling && isMobile) {
      this.openTab(this.currentTab.nextElementSibling.id)
    } else {
      this.openTab(this.questionContainer.querySelector('div.answers div'))
    }
  }

  static checkKey(keypressed) {
    if (!keypressed) {
      keypressed = document.all.event
    }

    switch (keypressed.keyCode) {
      case Event.KEY_UP:
        this.previousTab()
        break
      case Event.KEY_DOWN:
        this.nextTab()
        break
      default:
        return true
    }

    return false
  }

  static check(checkbox) {
    let completed = document.querySelector('.side-days');
    const ansPanel = checkbox.closest('.answers')
    const fields = ansPanel.querySelectorAll('input')
    for (let j = 0; j < fields.length && !completed; j++) {
      if (fields[j].checked) {
        completed = true
      }
    }

    const thisProd = ansPanel.id
    const thisDiv = document.getElementById('questionpopup_' + window.ff.currentNeedsQ )

    if (thisDiv.querySelectorAll('[id="' + thisProd + '"]').length > 1) {
      const thisProdLi = thisDiv.querySelector('.tab_question  > .items > .courses .products li[id="' + thisProd + '"]')
      if (thisProdLi) {
        this.currentItem = thisProdLi
      }
    }

    if (completed) {
      this.currentItem.classList.add('completed')
    } else {
      this.currentItem.classList.remove('completed')
    }
  }

  static ask_need_questions(evTrgt) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    window.ff.currentNeedsQ = 1

    this._dialog = Dialog.confirm(evTrgt, {
      extraClasses: 'modal-xl',
      content: {
        url: window.ff.pages.ajax,
        options: {
          parameters: {
            act: 'ask_need_questions',
            occasion_id: window.ff.occasion_id,
            category_id: window.ff.category_id,
            popupnr: this.current,
            edit: window.ff.edit,
            SES
          }
        }
      },
      events: {
        shown() {
          if (document.querySelector('.popup-1 > .summary')) {
            const oldDialog = document.querySelector('.popup-1 > .summary').closest('.modal')
            if (oldDialog) {
              const modal = Dialog.getInstance(oldDialog)
              modal.dispose()
            }
          }

          const checkboxes = document.querySelectorAll('div#questionpopup_' + window.ff.currentNeedsQ + ' input')
          for (const el of checkboxes) {
            if (!el.classList.contains('check_all')) {
              EventHandler.on(el, EVENT_CHANGE, ev => {
                if (!ev.target.checked) {
                  document.querySelector('.check_all').checked = false
                }
              })
            }
          }

          EventHandler.on(document.querySelector('.check_all'), EVENT_CHANGE, ev => {
            Needs.check_all(ev.target)
          })
          if (document.getElementById('questiontype' + window.ff.currentNeedsQ) && document.getElementById('questiontype' + window.ff.currentNeedsQ).getValue() == 'TABC') {
            Needs.resetTabs()
          }

          for (const el of document.querySelectorAll('[id^="questionpopup_"]')) {
            if (el.id !== 'questionpopup_' + window.ff.currentNeedsQ) {
              el.hide()
            }
          }

          if (!document.querySelector('[id^="questionpopup_' + window.ff.currentNeedsQ + '"]') &&
            document.querySelector('[name*="singleperson"]') &&
            document.querySelector('[name*="singleperson"]').value == 1) {
            const form = document.querySelector('[name*="singleperson"]').closest('form')
            form.submit()
          }

          if (document.querySelector('[id^="questionpopup_' + window.ff.currentNeedsQ + '"]')) {
            document.getElementById('questionpopup_' + window.ff.currentNeedsQ).show()
          }
        }
      },
      buttons: [
        {
          label: lang.diary_need_previous_btn || 'previous',
          action: ev => {
            this.prev(ev)
          }
        },
        {
          label: lang.diary_need_next_btn || 'next',
          action: ev => {
            this.next(ev)
          }
        }
      ]
    })
  }

  static setupMobilePrep(thisDiv) {
    document.body.classList.add('mobile')
    window.ff.PrepCourses = thisDiv.querySelectorAll('.form-row.course')
    window.ff.PrepProducts = thisDiv.querySelectorAll('.need_answers .form-row.product, .need_answers .form-row.dish, .tab_question  > .answerpanel > .answers > .answers')
    thisDiv.currentNeedsCourse = 0
    thisDiv.mobileState = 0

    for (const course of [...window.ff.PrepCourses]) {
      course.classList.add('hidden')
    }

    for (const product of [...window.ff.PrepProducts]) {
      product.classList.add('hidden')
    }

    if (window.ff.PrepCourses && window.ff.PrepCourses.length > 0) {
      thisDiv.mobileState++
      window.ff.PrepCourses[0].classList.remove('hidden')
    }

    if (window.ff.PrepProducts && window.ff.PrepProducts.length > 0) {
      thisDiv.mobileState++
      window.ff.PrepProducts[0].classList.remove('hidden')
    }

    this._setupMobileFlippers()
  }

  static next(ev) {
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
    window.ff.currentNeedsQ = currentDisplay.id.split('_')[1]
    const form = currentDisplay.closest('form')
    for (const el of ev.querySelectorAll('.btn')) {
      el.blur()
    }

    const validation = Number.parseInt(document.getElementById('validation_' + window.ff.currentNeedsQ).getValue(), 10)
    if (validation === 4) {
      const div = currentDisplay.querySelector('div.answers div.answers:not(.hidden)')
      const divs = currentDisplay.querySelectorAll('div.answers div.answers')
      let notUsed = true
      const fields = div.querySelectorAll('input')
      for (let j = 0; j < fields.length && notUsed; j++) {
        if (fields[j].checked) {
          notUsed = false
        }
      }

      if (notUsed && (div === divs[0] || div === divs[divs.length-1])) {
        Dialog.alert(form, { content: lang.diary_js_invalid_need_question })
        return false
      }

      if (div !== divs[divs.length - 1]) {
        this.resetTabs()
        return false
      }
    }

    if (this.validate()) {
      window.ff.currentNeedsQ++
      const nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)

      if (nextDisplay) {
        currentDisplay.hide()
        nextDisplay.show()

        if (isMobile && nextDisplay.querySelector('[name*="2-20"]')) {
          this.setupMobilePrep(nextDisplay)
        }

        // next_display.scrollIntoView();
        if (document.getElementById('questiontype' + window.ff.currentNeedsQ) && document.getElementById('questiontype' + window.ff.currentNeedsQ).getValue() == 'TABC') {
          this.currentItem = false
          this.resetTabs()
        }
        // this.resetHeight(this._dialog);
      } else {
        form.submit()
      }
    } else if (document.getElementById('questionid' + window.ff.currentNeedsQ).value == window.ff.whwquestionid) {
      Dialog.alert(form, { content: lang.diary_js_invalid_who_had_what })
    } else {
      Dialog.alert(form, { content: lang.diary_js_invalid_need_question })
    }
  }

  static prev(ev) {
    const currentDisplay = document.querySelector('[id^="questionpopup_"]:not(.hidden)')
    window.ff.currentNeedsQ = currentDisplay.id.split('_')[1]
    for (const el of ev.querySelectorAll('.btn')) {
      el.blur()
    }

    if (window.ff.currentNeedsQ <= 1) {
      const thisModal = document.getElementById('questionpopup_' + window.ff.currentNeedsQ).closest('.modal')
      const modal = Dialog.getInstance(thisModal)
      modal.dispose()
      return false
    }

    // document.getElementById("questionpopup_" +ff.currentNeedsQ).hide();
    window.ff.currentNeedsQ--
    const nextDisplay = document.getElementById('questionpopup_' + window.ff.currentNeedsQ)
    currentDisplay.hide()
    if (isMobile && nextDisplay.querySelector('[name*="2-20"]')) {
      this.setupMobilePrep(nextDisplay)
    }

    nextDisplay.show()
    if (document.getElementById('questiontype' + window.ff.currentNeedsQ) && document.getElementById('questiontype' + window.ff.currentNeedsQ).getValue() == 'TABC') {
      this.resetTabs()
    }

    return false
  }

  static show_occasion_summary(e) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    e = e || window.event
    const isObject = typeof e === 'object' // is the given argument an object
    const isEvent = isObject ? e.hasOwnProperty('target') : false

    this.constructor._dialog = Dialog.confirm(
      (isEvent) ? e.target : null,
      {
        extraClasses: 'modal-lg',
        content: {
          url: window.ff.pages.ajax,
          options: {
            parameters: {
              act: 'show_occasion_summary',
              occasion_id: window.ff.occasion_id,
              dish_id: window.ff.currentdish.dish_id || window.ff.short_process_id,
              usage_dish_id: window.ff.currentdish.usage_dish_id,
              completed: (!window.ff.courses || window.ff.current_course + 1 == window.ff.courses.length ? 1 : 0),
              edit: window.ff.edit,
              SES
            }
          }
        },
        buttons: [
          {
            label: lang.diary_close_summary_btn || 'add more',
            data: { 'ff-dismiss': 'dialog' }
          },
          {
            label: lang.diary_meal_complete_btn || 'Complete',
            action: ev => {
              this.ask_need_questions(ev)
            }
          }
        ]
      }
    )
  }

  static check_all(checkAllBox) {
    const checkboxes = this.get_inputs()
    for (const el of checkboxes) {
      el.checked = checkAllBox.checked
    }
  }

  static get_inputs() {
    return document.querySelectorAll('div#questionpopup_' + window.ff.currentNeedsQ + ' input')
  }

  static validate() {
    const validation = Number.parseInt(document.getElementById('validation_' + window.ff.currentNeedsQ).getValue(), 10)
    if (validation === 0) {
      return true
    }

    if (validation === 4) {
      const tabsOK = this.validateTabs()
      if (!tabsOK) {
        this.resetTabs()
      }

      return tabsOK
    }

    const rows = document.querySelectorAll('div#questionpopup_' + window.ff.currentNeedsQ + ' div.row:not(.empty)')
    const columnsUsed = []
    for (let i = 1; i < rows[0].querySelectorAll('.needshead').length; i++) {
      columnsUsed[i] = false
    }

    let allItemsUsed = true
    for (let nr = 1; nr < rows.length; nr++) {
      if (rows[nr].classList.contains('headrow')) {
        continue
      }

      if (rows[nr].querySelectorAll('input[type=checkbox]').length == 0) {
        continue
      }

      const columns = rows[nr].querySelectorAll('div')
      let currentItemUsed = false
      for (let i = 1; i < columns.length; i++) {
        const checkbox = columns[i].querySelector('input[type=checkbox]')
        if (checkbox && checkbox.checked == true) {
          columnsUsed[i] = true
          currentItemUsed = true
        }
      }

      if (!currentItemUsed) {
        allItemsUsed = false
      }
    }

    if (validation & 1 && !allItemsUsed) {
      return false
    }

    if (validation & 2) {
      for (let i = 1; i < columnsUsed.length; i++) {
        if (!columnsUsed[i]) {
          return false
        }
      }
    }

    return true
  }

  static validateTabs() {
    const divs = this.questionContainer.querySelectorAll('div.answers div.answers')
    for (let i = 0; i < divs.length; i++) {
      const fields = divs[i].querySelectorAll('input')
      let used = false
      for (let j = 0; j < fields.length && !used; j++) {
        if (fields[j].checked) {
          used = true
        }
      }

      if (!used) {
        return false
      }
    }

    return true
  }

  static _flipPage(e) {
    const formOK = this.validatePanel(e)
    if (!formOK) {
      return false
    }

    const targ = e.target
    if (targ instanceof Element) {
      this.el.mobileStates[this.el.mobileState - 1].hide()
      if (targ.classList.contains('prevBtn')) {
        this.el.mobileState--
      } else if (targ.classList.contains('nextBtn')) {
        this.el.mobileState++
      }

      if (this.el.mobileState === this.el.mobileStates.length) {
        const paddles = document.querySelectorAll('.button-cont [id*="_clone"]')
        const submits = document.querySelectorAll('.button-cont .hidden')

        this._NodeListforEach(paddles, (index, paddle) => {
          if (paddle instanceof Element) {
            paddle.hide()
          }
        })

        this._NodeListforEach(submits, (index, submit) => {
          if (submit instanceof Element) {
            submit.show()
          }
        })
      }

      this._showPageState()
    }
  }

  static validatePanel(div) {
    const divs = div.querySelectorAll('.need_answers .form-row.product:not(.hidden), .need_answers .form-row.dish:not(.hidden)')
    for (const thisDiv of [...divs]) {
      if (!thisDiv.classList.contains('hidden')) {
        let used = false
        let fields = thisDiv.querySelectorAll('input')

        for (let j = 0; j < fields.length && !used; j++) {
          if (!fields[j].getclosest('.hidden') && fields[j].checked) {
            used = true
          }
        }

        if (!used) {
          return false
        }
      }
    }

    return true
  }

  static getPreviousSibling(elem, selector) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling
    // If there's no selector, return the first sibling
    if (!selector) {
      return sibling
    }

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) {
        return sibling
      }

      sibling = sibling.previousElementSibling
    }
  }

  static _flipPanel(e) {
    const thisDiv = document.getElementById('questionpopup_' + window.ff.currentNeedsQ )
    const formOK = this.validatePanel(thisDiv)
    if (!formOK) {
      return false
    }
    window.ff.PrepCourses = thisDiv.querySelectorAll('.need_answers .form-row.course')
    window.ff.PrepProducts = thisDiv.querySelectorAll('.need_answers .form-row.product, .need_answers .form-row.dish, .tab_question  > .answerpanel > .answers > .answers')

    let dirTn = 'next'
    if ((e.currentTarget && e.currentTarget.classList.contains('prevBtn')) || (e.classList && e.classList.contains('prevBtn'))) {
      dirTn = 'prev'
    }

    //let itemsInCourse = thisDiv.querySelectorAll('.need_answers .form-row.course, .need_answers .form-row.product, .need_answers .form-row.dish')
    let itemsInCourse = thisDiv.querySelectorAll('.need_answers .form-row.product, .need_answers .form-row.dish, .tab_question  > .answerpanel > .answers > .answers')
    let courses = thisDiv.querySelectorAll('.need_answers .form-row.course')
    for (let j = 0; j < itemsInCourse.length; j++) {
      if (!itemsInCourse[j].classList.contains('hidden')) {
        let curCount = 0
        thisDiv.currentNeedsCourse = 0
        if (itemsInCourse) {
          if (thisDiv.mobileState < itemsInCourse.length && dirTn == 'next') {
            for (const item of [...itemsInCourse]) {
              item.classList.add('hidden')
            }

            itemsInCourse[thisDiv.mobileState].classList.remove('hidden')
            if (this.hasattr(itemsInCourse[thisDiv.mobileState], 'id')) {
              const thisProd = itemsInCourse[thisDiv.mobileState].id
              if (thisDiv.querySelectorAll('[id="' + thisProd + '"]').length > 1) {
                for (const neb of [...thisDiv.querySelectorAll('.tab_question  > .items > .courses .products li')]) {
                  neb.classList.remove('selected')
                }

                const thisProdLi = thisDiv.querySelector('.tab_question  > .items > .courses .products li[id="' + thisProd + '"]')
                if (thisProdLi) {
                  thisProdLi.classList.add('selected')
                }
              }
            }

            if (courses.length) {
              for (const course of [...courses]) {
                course.classList.add('hidden')
              }

              const prevCourse = this.getPreviousSibling(itemsInCourse[thisDiv.mobileState], '.form-row.course')
              if (prevCourse) {
                prevCourse.classList.remove('hidden')
              }
            }

            thisDiv.mobileState++
          } else if (thisDiv.mobileState == itemsInCourse.length && dirTn == 'next') {
            const paddles = document.querySelectorAll('.footer-buttons [id*="_clone"]')
            const submits = document.querySelectorAll('.footer-buttons .hidden')

            for (const submit of [...submits]) {
              submit.show()
            }

            document.getElementById(document.querySelector('.footer-buttons [id*="_clone"].nextBtn').id.replace('_clone', '')).click()
            for (const paddle of [...paddles]) {
              paddle.remove()
            }
          } else if (dirTn === 'prev') {
            let foundItem = false
loop1:
            for (let z = thisDiv.mobileState - 2; z >= 0; z--) {
              if (itemsInCourse[z].classList.contains('product') || itemsInCourse[z].classList.contains('dish')){
                foundItem = true
                break loop1
              }
            }

            if (thisDiv.mobileState > 1 && foundItem) {
              for (const item of [...itemsInCourse]) {
                item.classList.add('hidden')
              }
              itemsInCourse[thisDiv.mobileState - 2].classList.remove('hidden')
              if (courses.length) {
                for (const course of [...courses]) {
                  course.classList.add('hidden')
                }

                let prevCourse = this.getPreviousSibling(itemsInCourse[thisDiv.mobileState - 2], '.form-row.course')
                if (prevCourse) {
                  prevCourse.classList.remove('hidden')
                }
              }

/*
              if (itemsInCourse[thisDiv.mobileState - 2].classList.contains('course')) {
loop2:
                for (let z = thisDiv.mobileState - 3; z >= 0; z--) {
                  if (itemsInCourse[z].classList.contains('course')){
                    itemsInCourse[z].classList.remove('hidden')
                    break loop2
                  }
                }
                itemsInCourse[thisDiv.mobileState - 3].classList.remove('hidden')
                thisDiv.mobileState--
                itemsInCourse[thisDiv.mobileState - 1].classList.add('hidden')
              } else {
                itemsInCourse[thisDiv.mobileState - 2].classList.remove('hidden')
              }
*/
              thisDiv.mobileState--
            } else {
              const paddles = document.querySelectorAll('.footer-buttons [id*="_clone"]')
              const submits = document.querySelectorAll('.footer-buttons .hidden')

              for (const submit of [...submits]) {
                submit.show()
              }

              document.getElementById(document.querySelector('.footer-buttons [id*="_clone"].prevBtn').id.replace('_clone', '')).click()
              for (const paddle of [...paddles]) {
                paddle.remove()
              }
            }
          }

          break
        }
      }
    }
  }

  static _setupMobileFlippers() {
    if (isMobile) {
      const nodes = document.querySelectorAll('.footer-buttons button')
      for (const node of [...nodes]) {
        const clone = node.cloneNode(true)
        node.classList.add('hidden')
        clone.id += '_clone'

        if (node.previousSibling == null) {
          clone.classList.add('prevBtn')
        } else {
          clone.classList.add('nextBtn')
        }

        EventHandler.on(clone, EVENT_CLICK, event => {
          this._flipPanel(event)
        })
        document.querySelector('.footer-buttons').append(clone)
      }
    }
  }
}

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
  let trgt = event.target
  let attribs = Manipulator.getDataAttributes(trgt)

  if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
    event.preventDefault()
  }

  if (!trgt || !attribs.hasOwnProperty('action')) {
    trgt = event.target.closest(SELECTOR_DATA_TOGGLE)
    attribs = Manipulator.getDataAttributes(trgt)
  }

  Needs[attribs.action](trgt, (attribs.hasOwnProperty('data') ? attribs.data : {}))
})

export default Needs
