/* eslint-disable default-case */

import EventHandler from './dom/event-handler'
import api from './api'

const NAME = 'autocomplete'
const DATA_KEY = 'ff.autocomplete'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_MOUSEOVER = `mouseover${EVENT_KEY}`
const EVENT_BLUR = `blur${EVENT_KEY}`
const EVENT_INPUT = `input${EVENT_KEY}`
const EVENT_FOCUS = `focusin${EVENT_KEY}`
const EVENT_KEYUP = `keyup${EVENT_KEY}`
const EVENT_KEYDOWN = `keydown${EVENT_KEY}`

/**
 * Response handler for window.fetch response
 * @param {object} response
 * @return {object}
 */
const handleResponse = response => {
  return response
}

/**
 * @constant
 * @type {string}
 */
const SELECTED = 'item--selected'
const ACTIVE = 'autocompleting'
const VISIBLE = 'visible'
const ARROW_UP = 38
const ARROW_DOWN = 40
const ENTER = 13

/**
 * Default options object
 * @type {{minCharacters: number, fetchDelay: number, inputClassName: string, autoCompleteBaseClass: string, url: null}}
 */
const defaultOptions = {
  minCharacters: 2,
  fetchDelay: 100,
  inputClassName: 'input',
  autoCompleteBaseClass: 'autocomplete',
  url: null,
  target: null
}

/**
 * Class AutoComplete
 * @class
 */
class AutoComplete {
  /**
	 * Get indexes of search query in string
	 * @param {string} string
	 * @param {string} query
	 * @returns {Array}
	 */
  static getQueryIndexes(string, query) {
    const regex = new RegExp(query, 'gi')
    const allMatches = []
    let match
    while (match = regex.exec(string)) {
      allMatches.push(match.index)
    }

    return allMatches
  }

  /**
	 * Constructor
	 * @constructs
	 * @param {HTMLElement} element
	 * @param {object} options
	 */
  constructor(element, options) {
    if (!options.url) {
      throw new Error('Autocomplete url is mendatory')
    }

    this._options = { ...defaultOptions, ...options }
    this.element = element
    this.timeout = 0
    this.itemsList = ''
    this.items = []
    this.selectedIndex = -1
    this.isOpen = false
    this._load()
  }

  /**
	 * Load util
	 * @private
	 */
  _load() {
    this._createContainer()
    this._addEventListeners()
    this.element.setAttribute('autocomplete', 'off')
  }

  /**
	 * Add event listeners
	 * @private
	 */
  _addEventListeners() {
    EventHandler.on(this.element, EVENT_KEYDOWN, ev => this._onInput(ev))
    // EventHandler.on(this.element, EVENT_CLICK, ev => this._onFocus(ev))
    EventHandler.on(this.element, EVENT_FOCUS, ev => this._onFocus(ev))
    EventHandler.on(this.element, EVENT_BLUR, ev => this._onBlur(ev))
    EventHandler.on(this.element, EVENT_KEYUP, ev => this._onKeyup(ev))
    EventHandler.on(this.container, EVENT_CLICK, ev => this._onClick(ev))
    EventHandler.on(document, EVENT_CLICK, ev => this._onBodyClick(ev))
  }

  /**
	 * Create the container div
	 * @private
	 */
  _createContainer() {
    this.container = document.createElement('div')
    this.container.className = this._options.autoCompleteBaseClass
    this.element.parentNode.append(this.container)
  }

  /**
	 * Key up event handler
	 * Handles arrow up, arrow down and enter keys
	 * @private
	 * @param {Event} evt - keyup event object
	 */
  _onKeyup(evt) {
    // console.log(evt.keyCode);

    const key = evt.keyCode
    const isSelecting = key === ARROW_UP || key === ARROW_DOWN
    const isConfirming = key === ENTER

    if (!this.isOpen && this.items.length && isSelecting) {
      this._render(this.itemsList)
      this.isOpen = true
    }

    if (isSelecting && this.isOpen) {
      const direction = key === ARROW_UP ? 'up' : 'down'
      this._changeSelected(direction)
    }

    if (isConfirming && this.isOpen) {
      this._useSelected()
      this._onBlur()
    }
  }

  /**
	 * Blur event handler
	 * @private
	 */
  _onBlur(evt) {
    // Use timeout for click event trigger
    if (evt.target.id === 'searchdishes') {
      if (document.getElementById('searchother'))	{
        document.getElementById('searchother').value = evt.target.value
      }

      return false
    }

    this._render('')
    this.isOpen = false
    this.selectedIndex = -1
    this.element.classList.remove(this.autoCompletingClassName)
  }

  /**
	 * Focus event handler
	 * @private
	 */
  _onFocus() {
    if (this.items.length && !this.isOpen) {
      this._render(this.itemsList)
      this.isOpen = true
      this.element.classList.add(this.autoCompletingClassName)
    }
  }

  /**
	 * Input event handler
	 * @private
	 * @param {event} evt
	 */
  _onInput(evt) {
    // clearTimeout(this.timeout);
    this.value = evt.target.value
    // this.timeout = setTimeout(() => {

    this._fetchItems(this.value)
			.then(this._createAutoComplete.bind(this))
			.then(this._render.bind(this))

    // }, this.delay);
  }

  /**
	 * Click event handler
	 * @private
	 * @param {Event} evt - click event object
	 */
  _onClick(evt) {
    const itemClicked = evt.target.classList.contains(`${this.baseClassName}__item`)
    const btClicked = evt.target.classList.contains('btn')
    if (itemClicked) {
      this._useSelected(evt.target)
    } else if (btClicked) {
      setTimeout(() => {
        this._render('')
        this.isOpen = false
        this.selectedIndex = -1
        this.element.classList.remove(this.autoCompletingClassName)
      }, 150)
    }
  }

  /**
	 * Click event handler for body
	 * @private
	 * @param {Event} evt - click event object
	 */
  _onBodyClick(evt) {
    const el = evt.target
    if (el.getclosest(`.${this.baseClassName}`)) {
      return false
    }

    if (document.querySelector(`.${this.baseClassName}`)) {
      setTimeout(() => {
        this._render('')
        this.isOpen = false
        this.selectedIndex = -1
        this.element.classList.remove(this.autoCompletingClassName)
      }, 150)
    }
  }

  /**
	 * Create a DOM string to render the autocomplete list
	 * @private
	 * @param {string} value
	 * @returns {Promise|boolean}
	 */
  _fetchItems(value) {
    const canFetch = value.length >= this.minCharacters
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (canFetch) {
      const params = {
        search_text: value
      }

      return api.get(
        this._options.url,
        params,
        true
      )
			.then(handleResponse)
    }

    return Promise.resolve(false)
  }

  /**
	 * Create auto complete
	 * @private
	 * @param {Array} items
	 * @returns {HTMLElement}
	 */
  _createAutoComplete(items) {
    if (items) {
      this.items = items
      this.selectedIndex = -1
      // this.itemsList = this._getRenderList(this.items, this.value);
      this.isOpen = true
      this.element.classList.add(this.autoCompletingClassName)
    } else {
      this.items = []
      this.itemsList = ''
    }

    // return this.itemsList;
    return this.items
  }

  /**
	 * Change the selected item
	 * @private
	 * @param {string} direction
	 */
  _changeSelected(direction) {
    const canGoUp = this.selectedIndex > 0
    const canGoDown = this.selectedIndex < (this.items.length - 1)
    let index = this.selectedIndex
    if (direction === 'up' && canGoUp) {
      index--
    } else if (direction === 'down' && canGoDown) {
      index++
    }

    this._setSelected(index)
  }

  /**
	 * Set selected item
	 * @private
	 * @param {Number} index - index of the item to select
	 */
  _setSelected(index) {
    const items = [...this.container.querySelectorAll(`.${this.baseClassName}__item`)]
    this.selectedIndex > -1 && items[this.selectedIndex].classList.remove(this.selectedClassName)
    items[index].classList.add(this.selectedClassName)
    this.selectedIndex = index
  }

  /**
	 * Use the currently selected item as input value
	 * @private
	 * @param {HTMLElement|undefined} element - element to use
	 */
  _useSelected(element) {
    const selectedItem = element || this.container.querySelector(`.${this.selectedClassName}`)
    this.element.value = selectedItem.getAttribute('data-autocomplete-value')
  }

  /**
	 * Generate DOM html sting from item array
	 * @private
	 * @param {Array} items
	 * @param {string} query
	 * @returns {string}
	 */
  _getRenderList(items, query) {
    const listItems = items.map((item, index) => this._getRenderListItem(item, index, query))
    return `
            <ul class="${this.baseClassName}__list">
                ${listItems.join('\n')}
            </ul>`
  }

  /**
	 * Get DOM list item as a string
	 * @private
	 * @param {string} item
	 * @param {number} index
	 * @param {string} query
	 * @returns {string}
	 */
  _getRenderListItem(item, index, query) {
    const activeClass = index === this.selectedIndex ? ` ${this.selectedClassName}` : ''
    const queryIndexes = AutoComplete.getQueryIndexes(item, query)
    const highlightedClassname = `${this.baseClassName}__highlight`
    let highlighted = queryIndexes.length ? '' : item
    if (queryIndexes.length) {
      queryIndexes.reduce((previousIndex, currentIndex, position) => {
        const end = queryIndexes[position + 1] ? queryIndexes[position + 1] : item.length
        highlighted += `
					${item.slice(previousIndex, currentIndex)}
					<span class="${highlightedClassname}">${item.slice(currentIndex, currentIndex + query.length)}</span>
					${item.slice(currentIndex + query.length, end)}
                `
        return end
      }, 0)
    }

    const content = highlighted.replace(/\t/g, '').replace(/\n/g, '')
    return `<li class="${this.baseClassName}__item${activeClass}" data-autocomplete-value="${item}">${content}</li>`
  }

  /**
	 * Render the autocomplete list
	 * @private
	 * @param {string} html
	 */
  _render(html) {
    const hasHtml = html === ''
    const action = hasHtml ? 'remove' : 'add'

    this.container.innerHTML = html
    this.container.classList[action](`${this._options.autoCompleteBaseClass}--${VISIBLE}`)
  }

  static resetSearch() {
    const html = ''
    const container = document.querySelector('.autocomplete')
    container.innerHTML = html
    container.classList.remove(`autocomplete--${VISIBLE}`)
  }

  /**
	 * GETTERS
	 */

  /**
	 * Get minimum characters
	 * @returns {number|*}
	 */
  get minCharacters() {
    return this._options.minCharacters
  }

  /**
	 * Fetch delay
	 * @returns {number}
	 */
  get delay() {
    return this._options.fetchDelay
  }

  /**
	 * Auto complete root element class name
	 * @returns {string}
	 */
  get baseClassName() {
    return this._options.autoCompleteBaseClass
  }

  /**
	 * Selected item class name
	 * @returns {string}
	 */
  get selectedClassName() {
    return `${this.baseClassName}__${SELECTED}`
  }

  /**
	 * Auto completing class name
	 * @returns {string}
	 */
  get autoCompletingClassName() {
    return `${this._options.inputClassName}--${ACTIVE}`
  }
}

export default AutoComplete
