
import Data from './dom/data'
import api from './api'
import Dialog from './dialog'
import Help from './help'
import EventHandler from './dom/event-handler'

const NAME = 'search'

class Search {
  static search_other(ev) {
    const { target } = ev
    if (ev.keyCode == Event.KEY_RETURN) {
      ff.el.dishList.hide()
      Actions.add_dish(ev,{
        label: target.value
      })
    }

    return true
  }

  static do_search(text) {
    const netText = String(text.replace(/\s/, ''))
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    /* trigger keyboard to go away on mobile */

    if (netText.length < 2) {
      return
    }

    window.ff.el.dishContent.hide()
    window.ff.el.productContent.show()

    api.post(
      window.ff.pages.ajax,
      {
        act: 'search_products',
        search_mode: window.ff.search.mode,
        search_text: text,
        search_levels: document.getElementById('category').checked ? window.ff.search.levels : '',
        label: document.getElementById('category').checked ? window.ff.search.label : '',
        SES
      },
      true
    ).then(data => {
      /*window.ff.el.search.blur()*/
      window.ff.el.productContent.innerHTML = data
    })
  }

  static loadPage(item, params, pagenr) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (item.tagName === 'A' || (item.parentNode && item.parentNode.tagName === 'A') || item.tagName === 'AREA') {
      event.preventDefault()
    }

    for (const el of ff.el.dishContent.childNodes) {
      el.hide()
    }

    ff.el.dishContent.hide()
    params = JSON.parse(params)
    params.pagenr = pagenr
    params.SES = SES

    api.post(
      ff.pages.ajax,
      {
        ...params
      },
      true
    ).then(data => {
      ff.el.productContent.setInnerHTML(data)
      ff.el.productContent.show()
    })
  }

  static resetSearch() {
    document.getElementById('categorystuff').hide()
    document.getElementById('all').checked = true
    document.getElementById('category_label').style.opacity = 1

    document.querySelector('#category_label span').innerHTML = lang.diary_js_product_search_category
    document.getElementById('search').setAttribute('placeholder', lang.diary_js_please_enter_product)
    if (typeof ff === 'object' && ff.treeRoots) {
      for (const rootNode of ff.treeRoots) {
        rootNode.hideBranches()
      }
    }
  }

  static setCategory(lvlid, label) {
    const newlabel = lang.diary_js_product_search_category + ' <strong>' + label.trim() + '</strong>'
    document.getElementById('categorystuff').show()
    ff.search.levels = lvlid
    ff.search.label = label
    document.querySelector('#category_label span').innerHTML = newlabel
    document.getElementById('category_label').style.opacity = 1
    document.querySelector('#categorystuff').show()
    document.querySelector('#categorystuff input[name="in_category"][value="1"]').checked = true
  }
}

export default Search
