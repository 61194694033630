
import {
  getElementFromSelector,
  isVisible,
  isElement,
} from './util/index'

import EventHandler from './dom/event-handler'
import Dialog from './dialog'
import Help from './help'
import Breadcrumbs from './breadcrumbs'
import Search from './search'
import ProductTree from './producttree'
import Needs from './needs'
import TabContainer from './tabcontainer'
import SelectorEngine from './dom/selector-engine'
import api from './api'
import { isMobile } from './util'

const NAME = 'occcourses'
const DATA_KEY = 'ff.occcourses'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`
const MIN_SEARCH = 2

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_KEYUP = `keyup${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const MEAL_CONTENT = 'header.header .clone .dishesModal'

const MODAL_DIALOG = '.modal-dialog'
const MODAL_CONTENT = '.modal-body'
const MODAL_FOOTER = '.modal-footer'

class OccDetails {
  constructor(config) {
    this.config = config
    this.helpTopic = 'occasion_details'

    window.ff.usedProductCategories = []
    window.ff.answer_count = 0
    window.ff.is_dish = false
    window.ff.currentdish = {}
    window.ff.occasion_id = this.config.occasion_id || 0
    window.ff.category_id = this.config.category_id || 0
    window.ff.edit = this.config.edit || false
    window.ff.whwquestionid = this.config.whwquestionid || 0
    window.ff.search = {
      levels: 0,
      mode: 1
    }  
    window.ff.short_process_id = this.config.short_process_id || 0
    window.ff.trees = this.config.trees || []
    window.ff.treeRoots = this.config.treeRoots || []
    window.ff.courses = this.config.courses || []
    window.ff.current_course = 0
    window.ff.allow_next_course = false
    window.ff.mealComponents = []
    window.ff.delayToolTip = this.config.delayToolTip || 500
    window.ff.breadcrumbs = {}
    window.ff.hideCupboard = this.config.hideCupboard || 0
    window.ff.cupboardId = this.config.cupBoardId || 0
    // ff.display = DetailsDisplay.init()
    window.ff.occasion_label = this.config.occasion_label || ''
    window.ff.el = {
      productMenu: document.getElementById('contentleftmenu'),
      productContent: document.getElementById('product_content'),
      toIngredients: document.getElementById('to_dish_ingredients'),
      dishContent: document.getElementById('dish_content'),
      dishMenu: document.getElementById('contentleftdishrecipe'),
      dishList: document.getElementById('dish_list'),
      dishSearch: document.getElementById('dish_search'),
      productSearch: document.getElementById('product_search'),
      search: document.getElementById('search'),
      searchForm: document.getElementById('searchform'),
      addProducts: document.getElementById('add_products'),
      addDish: document.getElementById('add_dish'),
      favdishmenu: document.getElementById('favdishmenu'),
      favmenu: document.getElementById('favmenu')
    }

    window.ff.dishTabs = new TabContainer({
      buttons: window.ff.el.dishMenu,
      content: window.ff.el.dishContent,
      css: {
        active: 'selecteddish',
        content: 'tab'
      }
    })
    Breadcrumbs.create(window.ff.occasion_label)
    if (window.ff.trees) {
      if (window.ff.hideCupboard == 1){
        delete window.ff.trees[window.ff.cupboardId]
      }

      this.constructor.generateProductTrees(window.ff.trees)
    }

    if (window.ff.short_process_id > 0) {
      this.constructor.set_short_process(window.ff.short_process_id)
    } else if (window.ff.courses.length > 0) {
      this.constructor.next_course(window.ff.courses[0].usage_course_id, true)
    } else {
      Dialog.alert(document, { content: lang.diary_js_invalid_occasion })
    }

    window.ff.el.dishContent.show()
    if (window.ff.el.dishList.length) {
      window.ff.el.dishList.fadeOut(150)
    }

    Help.init({ topic: this.helpTopic })
    if (isMobile) {
      document.body.classList.add('mobile')
      window.ff.el.dishMenu.hide()
      // Get the element
      const elem = document.querySelector('.diary-side')
      // Create a copy of it
      const clone = elem.cloneNode(true)
      elem.hide()
      clone.classList.add('clone')
      const { children } = clone
      const dishesModal = document.createElement('div')
      dishesModal.classList.add('dishesModal')
      const kidArray = [...children]
      for (const el of kidArray) {
        if (isElement(el)) {
          if (el.id) {
            el.classList.add(el.id)
            el.removeAttribute('id')
          }

          if (!el.classList.contains('side-days')) {
            dishesModal.append(el)
            // el.remove()
          }
        }
      }

      clone.append(dishesModal)
      document.querySelector('header.header .nav').after(clone)
    }
  }

  // Getters
  static get DATA_KEY() {
    return DATA_KEY
  }

  static toggleMenu(menu, go) {
    if (go === 'hide') {
      menu.hide()
      menu.classList.remove('open')
    } else {
      menu.show()
      menu.classList.add('open')
    }
  }

  static toggleView() {
    const dishelements = [window.ff.el.dishContent, window.ff.el.dishSearch, window.ff.el.addProducts, window.ff.el.toIngredients]
    const productelements = [window.ff.el.productContent, window.ff.el.productSearch, window.ff.el.addDish] //

    if (window.ff.is_dish) {
      if (!window.ff.currentdish.usage_dish_id) {
        this.show_used_dishes()
      }

      for (const el of dishelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.show()
      }

      for (const el of productelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.hide()
      }

      this.toggleMenu(window.ff.el.addDish, 'hide')
      this.toggleMenu(window.ff.el.favmenu, 'hide')
      this.toggleMenu(window.ff.el.favdishmenu, 'hide')
    } else {
      if (window.ff.short_process_id <= 0) {
        window.ff.el.toIngredients.hide()
        this.toggleMenu(window.ff.el.favmenu, 'hide')
        this.toggleMenu(window.ff.el.favdishmenu, 'hide')
      } else if (!this.get_usage_course_id()) {
        this.toggleMenu(window.ff.el.favmenu, 'show')
        this.toggleMenu(window.ff.el.favdishmenu, 'hide')
      }

      for (const el of dishelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.hide()
      }

      window.ff.el.dishList.hide()
      for (const el of productelements) {
        if (el.querySelector('input[type="text"]')) {
          el.querySelector('input[type="text"]').value = ''
        }

        el.show()
      }

      window.ff.el.addDish.show()
    }
  }

  static set_is_dish(is_dish) {
    window.ff.is_dish = is_dish
    if (window.ff.is_dish) {
      window.ff.el.toIngredients.show()
      this.toggleMenu(window.ff.el.favmenu, 'hide')
      this.toggleMenu(window.ff.el.favdishmenu, 'show')
    } else {
      window.ff.currentdish = {}
      this.toggleMenu(window.ff.el.favdishmenu, 'hide')
    }
  }

  static set_short_process(id, course) {
    window.ff.short_process_id = id
    this.showDishmenu(window.ff.short_process_id, this)
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_products)
    this.set_is_dish(false)
    if (!this.get_usage_course_id()) {
      this.toggleMenu(window.ff.el.favmenu, 'show')
    }

    this.toggleView()
  }

  static generateProductTrees(trees) {
    const newDiv = document.createElement('div')
    for (const id in trees) {
      if ({}.hasOwnProperty.call(trees, id)) {
        const currentTree = trees[id]
        //parent, id, type, data, structure
        const treeObj = new ProductTree(
          'ajaxTreeView_' + currentTree.id, currentTree.id, 'category', 
          {
            data: '<span class="title01_left">' + currentTree.label + '</span>',
            label_long: currentTree.label
          }, currentTree.label)
        for (const item in currentTree.items) {
          if ({}.hasOwnProperty.call(currentTree.items, item)) {
            treeObj.createSublevel(
              currentTree.items[item].id, {
                data: currentTree.items[item].label,
                label_long: currentTree.items[item].label_long
              }
            )
          }
        }

        window.ff.treeRoots.push(treeObj)
        newDiv.classList.add('maindivider')
        document.getElementById('ajaxTreeView_' + currentTree.id).append(newDiv)
      }
    }
  }

  static next_course(usageCourseId, init) {
    const isObject = typeof event  ==='object' // is the given argument an object
    const isEvent = isObject ? event.hasOwnProperty('target') : false

    if (!window.ff.allow_next_course && !init) {
      Dialog.alert(((isEvent) ? event.target : null), { content: lang.diary_js_next_course_not_allowed })
      return false
    }

    // if (isEvent && event.target.nodeType != 9) {
      // let trgt = event.target
      // if (trgt) {
    const modal = document.querySelector('.modal')
    if (modal) {
      const pop = Dialog.getInstance(modal)
      pop.hide(event)
    }
      // }
    // }

    if (usageCourseId > 0) {
      if (Number.isInteger(window.ff.courses)){

      } else {
        for (const [i, course] of window.ff.courses.entries()) {
          if (usageCourseId == course.usage_course_id) {
            window.ff.current_course = i
          }
        }
      }
    } else if (window.ff.current_course + 1 < window.ff.courses.length) {
      window.ff.current_course++
    }

    this.showSelectedItems()
    Breadcrumbs.reset()
    Breadcrumbs.add(window.ff.courses[window.ff.current_course].label)
    if (window.ff.courses[window.ff.current_course].recipe_id > 0) {
      this.set_short_process(window.ff.courses[window.ff.current_course].recipe_id)
    } else {
      window.ff.short_process_id = 0
      this.set_is_dish(true)
      Breadcrumbs.add(lang.diary_js_breadcrumb_adding_dish)
      this.toggleView()
      for (const el of [
        window.ff.el.productMenu,
        window.ff.el.productSearch,
        window.ff.el.dishMenu,
        window.ff.el.favmenu,
        window.ff.el.favdishmenu
      ]) {
        el.hide()
      }
    }

    window.ff.el.dishContent.innerHTML = ''
    window.ff.el.productContent.innerHTML = lang.diary_js_product_content
    Search.resetSearch()
    document.getElementById('searchdishes').setAttribute('placeholder', lang.diary_js_please_enter_dish)
    return window.ff.current_course
  }

  static get_usage_course_id() {
    if (!window.ff.courses || window.ff.courses.length <= 0) {
      return false
    }

    return window.ff.courses[window.ff.current_course].usage_course_id
  }

  static show_recipe_items(label, checkdiv) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (document.getElementById(checkdiv)) {
      document.getElementById('check' + checkdiv.id).checked = true
      checkdiv.classList.add('selecteddish')
      if (window.ff.usedProductCategories.includes(checkdiv.id)) {
        return
      }

      window.ff.usedProductCategories.push(checkdiv.id)
    }

    window.ff.el.dishContent.innerHTML = ''
    window.ff.el.dishContent.show()

    api.post(
      window.ff.pages.ajax,
      {
        act: 'show_products_in_cupboard',
        dish_id: window.ff.currentdish.dish_id || window.ff.short_process_id,
        label,
        type: checkdiv ? checkdiv.id : 0,
        SES
      },
      true
    ).then(data => {
      this.el.dishContent.setInnerHTML(data)
    })
    window.ff.el.productSearch.show()
  }

  static browseHierarchy(levels, label, preview) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    window.ff.search.levels = levels
    window.ff.search.mode = 1
    window.ff.search.label = label
    window.ff.el.productContent.show()
    window.ff.el.dishContent.hide()

    api.post(
      window.ff.pages.ajax,
      {
        act: 'browse_hierarchy',
        levels,
        label,
        preview: preview || 0,
        SES
      },
      true
    ).then(data => {
      window.ff.el.productContent.setInnerHTML(data)
    })
  }

  static showDishmenu(dishId, localff, usage_dish_id) {
    const fnFF = window.ff || localff
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    let recipeId = 0

    if (dishId > 0) {
      recipeId = dishId
    } else if (fnFF.courses && fnFF.courses.length > 0) {
      recipeId = fnFF.courses[fnFF.current_course].recipe_id
    } else {
      recipeId = fnFF.short_process_id
    }

    api.post(
      window.ff.pages.ajax,
      {
        act: 'show_dish_recipe',
        occasion_id: fnFF.occasion_id,
        recipe_id: recipeId,
        is_dish: dishId > 0 ? 1 : 0,
        SES
      },
      true
    ).then(data => {
      fnFF.el.dishMenu.setInnerHTML(data)
    })

    fnFF.el.dishMenu.show()
    fnFF.el.productMenu.hide()
    fnFF.el.dishContent.innerHTML = ''
  }

  static showSelectedItems() {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''
    window.ff.mealComponents = document.querySelectorAll('#diarymealcomponents, .diarymealcomponents')
    const panelCounts = {
      dishes: Number.parseInt(window.ff.dishesCount, 10),
      products: Number.parseInt(window.ff.productsCount, 10),
      courses: Number.parseInt(window.ff.coursesCount, 10)
    }
    for (const el of window.ff.mealComponents) {
      if (el) {
        el.show()
      }
    }

    if (isMobile) {
      let potContainer = {}
      // eslint-disable-next-line no-negated-condition
      if (!document.querySelector('.dishCounter')) {
        potContainer = document.createElement('div')
        potContainer.classList.add('dishCounter')
        potContainer.append(document.createElement('span'))
        document.querySelector('.diary-side .side-days').append(potContainer)
      }

      if (document.querySelector('.dishCounter')) {
        EventHandler.off(document.querySelector('.dishCounter'), EVENT_KEY)
        EventHandler.on(document.querySelector('.dishCounter'), EVENT_CLICK, ev => {
          ev.stopPropagation()
          const dishesModalContent = document.createElement('div')
          const dishesModal = document.querySelector(MEAL_CONTENT).cloneNode(true)
          dishesModalContent.append(dishesModal)
          let btns = dishesModalContent.querySelectorAll('div[class*="btn"]:not(.hidden) a.btn')
          for (const el of btns) {
            el.hide()
          }

          const conf = {
            content: dishesModalContent.innerHTML
          }
          btns = document.querySelectorAll('header.header .clone .dishesModal div[class*="btn"]:not(.hidden) a.btn')
          if (btns.length === 1) {
            for (const el of btns) {
              conf.btnOKLabel = el.textContent || 'OK'
            }
          } else {
            btns = document.querySelectorAll('header.header .clone .dishesModal div[class*="btn"]:not(.hidden) a.btn')
            conf.buttons = [{
              label: lang.diary_cancel_btn || 'cancel',
              data: { 'ff-dismiss': 'dialog' },
              action: evt => {
                const popup = evt.target.getclosest('.modal')
                if (popup) {
                  const pop = Dialog.getInstance(popup)
                  pop.dispose()
                }

                document.body.classList.remove('modal-open')
              }
            }]

            for (const el of btns) {
              conf.buttons.push({
                label: el.innerText,
                data: { 'ff-dismiss': 'dialog' },
                action: ev => {
                  window.location.href = el.href
                }
              })
            }
          }

          conf.btnOKCallback = (popup, evt) => {
            popup = evt.target.getclosest('.modal')

            const btns = document.querySelectorAll('header.header .clone .dishesModal div[class*="btn"]:not(.hidden)')

            if (popup) {
              const pop = Dialog.getInstance(popup)
              pop.dispose()
              document.body.classList.remove('modal-open')
            }

            for (const el of btns) {
              if (el.classList.contains('nextcoursebtn')) {
                OccDetails.next_course()
              } else if (el.classList.contains('completeoccasionbtn')) {
                Needs.show_occasion_summary()
              } else if (el.classList.contains('cancelbtn')) {
                window.location.href = el.querySelector('a').href
              }
            }
          }

          const el = Dialog.confirm(
            document.querySelector('header.header .clone .dishCounter'),
            conf
          )
          window.ff.mealComponents = document.querySelectorAll('#diarymealcomponents, .diarymealcomponents')
        })
      }
    }

    api.post(
      window.ff.pages.ajax,
      {
        act: 'show_selected_items',
        occasion_id: window.ff.occasion_id,
        usage_dish_id: window.ff.currentdish.usage_dish_id,
        usage_course_id: this.get_usage_course_id(),
        edit: window.ff.edit,
        SES
      },
      true
    ).then(data => {
      for (const el of window.ff.mealComponents) {
        if (el) {
          el.setInnerHTML(data)
          if (Number.parseInt(window.ff.dishesCount, 10) > panelCounts.dishes ||
          Number.parseInt(window.ff.productsCount, 10) > panelCounts.products ||
          Number.parseInt(window.ff.coursesCount, 10) > panelCounts.courses) {
            document.querySelector('.diary-side.clone').classList.add('pulse')
            setTimeout(() => {
              document.querySelector('.diary-side.clone').classList.remove('pulse')
            }, 3000)
          }

          const potContainer = document.querySelector('.dishCounter span')
          if (potContainer !== null) {
            potContainer.setInnerHTML(window.ff.totalCount)
          }
        }
      }
    })

    window.ff.is_dish = ((window.ff.is_dish === 'true') ? true : ((window.ff.is_dish === 'false') ? false : window.ff.is_dish))

    if (isMobile && document.querySelector('.diary-side.clone')) {
      if (window.ff.is_dish) {
        document.querySelector('.diary-side.clone').classList.add('dish')
        document.querySelector('.diary-side.clone').classList.remove('pot')
      } else {
        document.querySelector('.diary-side.clone').classList.remove('dish')
        document.querySelector('.diary-side.clone').classList.add('pot')
      }
      /** reset to start of select if mobile and just selected product */
      Breadcrumbs.reset()
      Search.resetSearch()
/*
      for (const el of [
        window.ff.el.dishMenu,
        window.ff.el.favdishmenu
      ]) {
        el.hide()
      }
*/
      Search.resetSearch()
      //OccDetails.show_ingredients()
      const oldParents = document.querySelectorAll('#ajaxTreeView .subcatcurrent')
      for (const el of oldParents) {
        el.classList.remove('subcatcurrent')
      }
      const oldCurrents = document.querySelectorAll('#ajaxTreeView .current')
      for (const el of oldCurrents) {
        el.classList.remove('current')
      } 
      const oldCurrPar = document.querySelectorAll('.curparent')
      for (const el of oldCurrPar) {
        el.classList.remove('curparent')
      }

      window.ff.el.dishContent.innerHTML = ''
      //window.ff.el.productContent.innerHTML = lang.diary_js_product_content
      //window.ff.el.productContent.innerHTML = ''
      window.ff.el.productContent.show()
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      /*
      ff.items = '[]';
      ff.is_dish = '';
      ff.dishesCount = '0';
      ff.productsCount = '1';
      ff.coursesCount = '0';
      */
    }
  }

  static showMyFavorites() {
    if (window.ff.el.productContent.classList.contains('hidden')) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    TabContainer.deactivate()
    window.ff.el.productContent.show()
    window.ff.el.dishMenu.show()
    api.post(
      window.ff.pages.ajax,
      {
        act: 'show_my_favorites',
        category_id: window.ff.category_id,
        SES
      },
      true
    ).then(data => {
      window.ff.el.productContent.setInnerHTML(data)
    })
    } else {
      window.ff.el.productContent.hide()
      window.ff.el.dishMenu.hide()
    }
    Search.resetSearch()
    OccDetails.show_ingredients()
  }

  static show_ingredients() {
    window.ff.el.productContent.innerHTML = ''
    window.ff.el.dishContent.innerHTML = ''
    window.ff.el.productMenu.hide()
    window.ff.el.productSearch.show()
    if (!isMobile) window.ff.el.dishMenu.show()
    //Windows.closeAllModalWindows()
    TabContainer.deactivate()
  }

  static show_favorite_ingredients() {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    if (!window.ff.currentdish) {
      return
    }

    TabContainer.deactivate()
    window.ff.el.productContent.show()

    api.post(
      window.ff.pages.ajax,
      {
        act: 'show_favorite_ingredients',
        dish_id: window.ff.currentdish.dish_id,
        label: window.ff.currentdish.label,
        SES
      },
      true
    ).then(data => {
      window.ff.el.productContent.setInnerHTML(data)
    })
    Search.resetSearch()
  }

  static show_used_dishes(pagenr) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    api.post(
      window.ff.pages.ajax,
      {
        act: 'show_used_dishes',
        pagenr,
        SES
      },
      true
    ).then(data => {
      window.ff.el.dishContent.setInnerHTML(data)
    })
  }

  static show_dish_view() {
    window.ff.currentdish = {}
    this.set_is_dish(true)
    Breadcrumbs.remove(1)
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_dish)
    //Windows.closeAllModalWindows()
    this.toggleView()
    const hideitems = document.querySelectorAll('ajaxTreeView ._root')
    for (const el of hideitems) {
      el.show()
    }

    window.ff.el.dishSearch.show()
    for (const el of [window.ff.el.productMenu, window.ff.el.productSearch, window.ff.el.dishMenu]) {
      el.hide()
    }

    window.ff.el.productContent.innerHTML = ''
    Search.resetSearch()
    this.show_used_dishes()
    this.showSelectedItems()
    document.getElementById('searchdishes').value = ''
    document.getElementById('searchdishes').focus()
  }

  static show_product_view(hideitems) {
    Breadcrumbs.remove()
    Breadcrumbs.add(lang.diary_js_breadcrumb_adding_products)
    this.set_is_dish(false)
    // Windows.closeAllModalWindows();
    this.toggleView()
    for (const el of [window.ff.el.dishSearch, window.ff.el.dishMenu]) {
      el.hide()
    }

    if (window.ff.short_process_id > 0) {
      window.ff.el.productMenu.hide()
      this.showDishmenu(window.ff.short_process_id, window.ff)
      if (!this.get_usage_course_id()) {
        window.ff.el.favmenu.show()
      }
    } else {
      for (const el of [window.ff.el.productMenu, window.ff.el.productSearch]) {
        el.show()
      }

      window.ff.el.favmenu.hide()
    }

    for (const el of [window.ff.el.dishContent, window.ff.el.productContent]) {
      el.innerHTML = ''
    }

    TabContainer.deactivate()
    this.showSelectedItems()
    if (!hideitems) {
      for (const el of document.querySelectorAll('#ajaxTreeView ._root')) {
        el.show()
      }
    }
  }
}

EventHandler.on(document.getElementById('search'), EVENT_KEYUP, ev => {
  if (ev.target.value.length > MIN_SEARCH) {
    Search.do_search(ev.target.value)
  }
})

export default OccDetails
