import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import Help from './help'
import SelectorEngine from './dom/selector-engine'
import { isMobile } from './util'

const DATA_KEY = 'ff.occattributes'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`
const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`
const SELECTOR_DATA_TOGGLE = '[data-ff-target="guest"]'

const Default = {
  ate: 1,
  lunchbox: 2,
  not: 3,
  out: 4,
  delivery: 5
}

class OccAttributes {
  constructor(config) {
    this.config = config
    this.helpTopic = 'occasion_attributes'
    this.dayOverview = window.ff.pages.day
    this.persons = this.config.persons
    this.attendance = this.config.attendance
    this.wfh = this.config.wfh
    this.guests = 0
    this.questions = this.config.questions
    this.attendanceQuestion = this.config.attendance_question
    this.values = {
      ate: Default.ate,
      lunchbox: Default.lunchbox,
      not: Default.not,
      out: Default.out,
      delivery: Default.delivery
    }
    this.el = {}
    this.el.that = this
    this.el.attendanceInputs = document.querySelectorAll('#attendance input[type=radio][id^="per_"]')
    this.el.attendanceTimeLabel = document.querySelectorAll('#attendance .meal_att_column.timelabel')[0]
    this.el.attendanceTime = document.querySelectorAll('#attendance select[name^="attendance_time"]')
    this.el.attendanceSelects = document.querySelectorAll('#attendance select[id^="per_"]')
    this.el.guestsInputs = document.querySelectorAll('#attendance #guests input, #attendance #guests select[id^="per_"]')
    this.el.guestsTime = document.querySelectorAll('#attendance #guests select[name^="attendance_time"]')
    this.el.guestCount = document.getElementById('guest_count')
    this.el.guestsText = document.getElementById('guests_text')
    this.el.form = document.getElementById('f1')
    this.el.allAte = document.getElementById('all_ate')
    this.el.attributesTable = document.getElementById('attribute_questions')
    this.timedAttendance = [ Default.ate, Default.lunchbox ]
    if (this.config.ooh == 1) this.timedAttendance = [ Default.ate, Default.lunchbox, Default.out, Default.delivery ]
    if (isMobile) {
      document.body.classList.add('mobile')
      this.el.mobileStates = document.querySelectorAll('.question-group')
      this.el.mobileState = 1
      for (const page of [...this.el.mobileStates]) {
        page.classList.add('hidden')
      }

      // Get the element
      const elem = document.querySelector('.side-days')
      // Create a copy of it
      const clone = elem.cloneNode(true)
      elem.hide()
      clone.classList.add('clone')
      // Inject it into the DOM
      document.querySelector('header.header .nav').after(clone)
      this._showPageState()
      this._setupMobileFlippers()
    }

    EventHandler.on(this.el.form, EVENT_SUBMIT, event => {
      return this.validateMealForm(event)
    })
    EventHandler.on(this.el.allAte, EVENT_CHANGE, () => {
      this.checkRadioButtons()
    })
    EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
      const trgt = event.target

      if (trgt.tagName === 'A' || (trgt.parentNode && trgt.parentNode.tagName === 'A') || trgt.tagName === 'AREA') {
        event.preventDefault()
      }

      const config = {
        ...Manipulator.getDataAttributes(trgt)
      }
      if (config.toggle === 'subtract') {
        this.subGuest()
      } else {
        this.addGuest()
      }
    })
    if (document.body.classList.contains('mobile')) {
      this._copyAttendeeRadioToSelect()
      for (const ateSelects of this.el.attendanceSelects) {
        EventHandler.on(ateSelects, EVENT_CHANGE, event => {
          // use name - assumes 1-n for attendees and 25 for guests
          const selectId = event.target.name
          const selectValue = event.target.value
          const r = /\d+/
          const personId = Number.parseInt(selectId.match(r), 10)
          document.querySelector('#attendance input[type=radio][id^="per_' + personId + '_att_' + selectValue + '"]').checked = true
          this._checkAte()
          if (event.target.value > this.values.lunchbox) {
            this._uncheckAllAte()
          }

          this._checkAttendance(personId, event.target)
        })
      }
    } else {
      for (const ateRadio of this.el.attendanceInputs) {
        EventHandler.on(ateRadio, EVENT_CLICK, event => {
          // use name - assumes 1-n for attendees and 25 for guests
          const radioId = event.target.name
          const r = /\d+/
          const personId = Number.parseInt(radioId.match(r), 10)
          this._checkAte()
          if (event.target.value > this.values.lunchbox) {
            this._uncheckAllAte()
          }

          this._checkAttendance(personId, event.target)
        })
      }
    }

    for (const timeSelect of this.el.attendanceTime) {
      EventHandler.on(timeSelect, EVENT_CLICK, event => {
        this._changeTime(event.target)
      })
      EventHandler.on(timeSelect, EVENT_CHANGE, event => {
        this._changeTime(event.target)
      })
    }

    this.el.attributesInputs = document.querySelectorAll('#attribute_questions input, #attribute_questions select')
    this.updateGuestsText()
    this._checkAte()
    Help.init({ topic: this.helpTopic })
  }

  // Getters

  static get Default() {
    return Default
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  _copyAttendeeRadioToSelect() {
    for (const select of this.el.attendanceSelects) {
      while (select.options.length > 0) {
        select.remove(0)
      }

      let unselectedText = '---'
      if (select.dataset.placeholder) {
        unselectedText = select.dataset.placeholder
      }

      const option = document.createElement('option')
      option.text = unselectedText
      option.value = ''
      select.add(option)
      if (document.body.classList.contains('mobile')) {
        select.disabled = false
      }
    }

    for (const attendeeRadio of [...this.el.attendanceInputs]) {
      const thisId = attendeeRadio.id
      const opt = document.createElement('option')
      opt.text = attendeeRadio.previousElementSibling.textContent
      opt.value = attendeeRadio.value
      opt.selected = attendeeRadio.checked
      if (document.body.classList.contains('mobile')) {
        attendeeRadio.disabled = false
      }

      const selId = thisId.replace('_' + thisId.replace(/^(?:[^_]*_){2}/g, ''), '')
      document.getElementById(selId).add(opt)
    }
  }

  updateGuestsText() {
    let guesttxt = ''
    switch (Number(this.el.guestCount.value)) {
      case 0:
        for (const inp of this.el.guestsInputs) {
          SelectorEngine.parents(inp, 'li')[0].hide()
        }

        if (this.el.attendanceTime.length > 0) {
          for (const timeSelect of this.el.guestsTime) {
            timeSelect.hide()
          }
        }

        guesttxt = lang.diary_js_add_guests
        break
      case 1:
        for (const inp of this.el.guestsInputs) {
          SelectorEngine.parents(inp, 'li')[0].show()
        }

        if (this.el.guestsTime.length > 0) {
          for (const timeSelect of this.el.guestsTime) {
            timeSelect.show()
          }
        }

        guesttxt = this.el.guestCount.value + '&nbsp;' + lang.diary_js_guest
        break
      default:
        for (const inp of this.el.guestsInputs) {
          SelectorEngine.parents(inp, 'li')[0].show()
        }

        if (this.el.guestsTime.length > 0) {
          for (const timeSelect of this.el.guestsTime) {
            timeSelect.show()
          }
        }

        guesttxt = this.el.guestCount.value + '&nbsp;' + lang.diary_js_guests
        break
    }

    this.el.guestsText.innerHTML = guesttxt
  }

  addGuest() {
    this.el.guestCount.value++
    this.updateGuestsText()
    this._checkAte()
  }

  subGuest() {
    if (this.el.guestCount.value < 1) {
      return false
    }

    this.el.guestCount.value--
    this.updateGuestsText()
    this._checkAte()
  }

  _NodeListforEach(array, callback, scope) {
    for (let i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  }

  _flipPage(e) {
    const formOK = this.validateMealForm(e)
    if (!formOK) {
      return false
    }

    const targ = e.target.closest("button")
    if (targ instanceof Element) {
      this.el.mobileStates[this.el.mobileState - 1].hide()
      if (targ.classList.contains('prevBtn')) {
        this.el.mobileState--

        if (this.el.mobileState === 0) {
          const vback = document.querySelector('.button-cont a[href^="http"]').getAttribute('href')
          window.location.href = vback
        }
      } else if (targ.classList.contains('nextBtn')) {
        this.el.mobileState++
      }

      const ateVals = this.countAteValues()
      if ((this.el.mobileState === this.el.mobileStates.length) || (Number.parseInt(ateVals.ate, 10) === 0)) {
        const paddles = document.querySelectorAll('.button-cont [id*="_clone"]')
        const submits = document.querySelectorAll('.button-cont .hidden')

        this._NodeListforEach(paddles, (index, paddle) => {
          if (paddle instanceof Element) {
            paddle.hide()
          }
        })

        this._NodeListforEach(submits, (index, submit) => {
          if (submit instanceof Element) {
            submit.show()
          }
        })

        if (Number.parseInt(ateVals.ate, 10) === 0) {
          document.querySelector('.button-cont button[type="submit"]').click()
        }
      }

      this._showPageState()
    }
  }

  _setupMobileFlippers() {
    if (isMobile) {
      const nodes = document.querySelectorAll('.button-cont .continue1')
      for (const node of [...nodes]) {
        const clone = node.cloneNode(true)
        node.classList.add('hidden')
        clone.id += '_clone'

        if (clone.hasAttribute('href')) {
          clone.removeAttribute('href')
          clone.classList.add('prevBtn')
        }

        if (clone.hasAttribute('type')) {
          clone.setAttribute('type', 'button')
          clone.classList.add('nextBtn')
        }

        EventHandler.on(clone, EVENT_CLICK, event => {
          this._flipPage(event)
        })
        document.querySelector('.button-cont').append(clone)
      }
    }
  }

  _showPageState() {
    this.el.attributesTable.show()
    if (isMobile) {
      let curCount = 0
      for (const page of [...this.el.mobileStates]) {
        curCount++
        if (curCount === this.el.mobileState) {
          page.show()
        }
      }
    }
  }

  _checkAte() {
    const ateVals = this.countAteValues()
    let NumEat = 0;

    NumEat = Number.parseInt(ateVals.ate, 10)
    if (this.config.ooh == 1) NumEat += Number.parseInt(ateVals.out, 10)

    if (NumEat == 0 ) {
      if (this.el.attendanceTime.length > 0) {
        for (const timeSelect of this.el.attendanceTime) {
          timeSelect.parentElement.hide()
        }
      }

      this.el.attributesTable.hide()
    } else {
      /*
      if (this.el.attendanceTime.length > 0) {
        for (const timeSelect of this.el.attendanceTime) {
          timeSelect.hide()
        }
      }
      */
      this._showPageState()

      for (const attendees of this.el.attendanceInputs) {
        const checkbox = document.getElementById(attendees.id)
        if (checkbox && checkbox.checked === true) {
          const ateVal = Number.parseInt(checkbox.value, 10)
          const matches = checkbox.name.match(/\[(.*?)\]/)
          let submatch = false
          if (matches) {
            submatch = matches[1]
          }

          if (!this.timedAttendance.includes(ateVal) &&
            this.el.attendanceTime.length > 0 && submatch) {
            document.getElementById('attendance_time_' + submatch).parentElement.hide()
          }
        }
      }
    }

    if (document.body.classList.contains('mobile')) {
      this._copyAttendeeRadioToSelect()
    }

    return ateVals.ate
  }

  _checkAttendance(personId, element) {
    if (this.el.attendanceTime.length <= 0) {
      return true
    }

    if (this.attendance &&
       this.attendance[personId] &&
        ( this.timedAttendance.includes(parseInt(this.attendance[personId].attendance, 10)) ) &&
          (!this.timedAttendance.includes(parseInt(element.value, 10)))) {
      Dialog.confirm({
        content: lang.diary_js_confirm_override_whw,
        buttons: [{
          label: lang.diary_js_ok,
          action() {
            element.checked = true
            if (document.querySelector('attendance_time_' + personId)) {
              document.querySelector('attendance_time_' + personId).parentElement.hide()
            }

            return true
          }
        }]
      })
      return false
    }

    if (this.timedAttendance.includes(parseInt(element.value, 10)) && this.el.attendanceTime.length > 0) {
      document.getElementById('attendance_time_' + personId).parentElement.show()
      document.getElementById('attendance_time_' + personId).focus()
    } else {
      document.getElementById('attendance_time_' + personId).parentElement.hide()
    }

    return true
  }

  _changeTime(element) {
    if (element.value == '') {
      return
    }

    for (const timeVal of this.el.attendanceTime) {
      if (timeVal.value == '') {
        timeVal.value = element.value
      }
    }
  }

  getAtebox(timeEl) {
    return document.querySelector(timeEl.parentNode.parentNode + ' input[value=' + this.values.ate + ']')
  }

  checkRadioButtons() {
    if (!this.el.allAte.checked) {
      return false
    }

    const radios = this.el.attendanceInputs
    for (const atTimeRadio of radios) {
      if (Number.parseInt(atTimeRadio.value, 10) === Number.parseInt(Default.ate, 10)) {
        atTimeRadio.checked = true
      }
    }

    this._checkAte()

    if (this.el.attendanceTime.length > 0) {
      this.el.attendanceTimeLabel.show()
      for (const timeSelect of this.el.attendanceTime) {
        timeSelect.parentElement.show()
      }

      if (Number.parseInt(this.el.guestCount.value, 10) === 0) {
        for (const timeSelect of this.el.guestsTime) {
          timeSelect.hide()
        }
      }
    }
  }

  _uncheckAllAte() {
    const guestIndex = 'per_' + this.persons + '_att_' + Default.ate
    if (this.el.guestCount > 0 && document.getElementById(guestIndex).checked === true) {
      return false
    }

    this.el.allAte.checked = false
  }

  countAteValues() {
    let dideat = 0
    let didEatOut = 0
    let didntEatAtAll = 0
    const guestInputs = [...this.el.guestsInputs]

    for (const inp of this.el.attendanceInputs) {
      const checkbox = document.getElementById(inp.id)
      if (Boolean(guestInputs.find((node, id) => node.id === inp.id)) &&
        Number.parseInt(this.el.guestCount.value, 10) <= 0) {
      } else if (checkbox && checkbox.checked === true) {
        switch (Number.parseInt(checkbox.value, 10)) {
          case this.values.ate:
          case this.values.lunchbox:
          case this.values.delivery:    
            dideat++
            break
          case this.values.out:
            didEatOut++
            break
          case this.values.not:
            didntEatAtAll++
            break
          default:
            break
        }
      }
    }

    return {
      ate: dideat,
      out: didEatOut,
      not: didntEatAtAll
    }
  }

  validateMealForm(event) {
    let errMsg = ''
    const ate = this.countAteValues()
    const persons = this.persons + (this.el.guestCount.value > 0 ? 1 : 0)
    const unanswered = (typeof this.questions === 'Object') ? this.questions : JSON.parse(this.questions)

    if (Number.parseInt(ate.out + ate.not, 10) === Number.parseInt(persons, 10)) {
      this.el.form.action = this.config.day_overview
    } else if (ate.ate + ate.out + ate.not != persons) {
      errMsg += lang.diary_js_message_complete_entries + ' "' + this.config.attendance_description + '"<br />'
    }

    if (ate.ate > 0) {
      if (this.el.attendanceTime.length > 0) {
        for (let y = 0; y < this.el.attendanceTime.length - (this.el.guestCount.value > 0 ? 0 : 1); y++) {
          const checkbox = document.querySelector('#attendance input:checked[type=radio][id^=\'per_' + y + '\']')
          if (this.el.attendanceTime[y].value == '' &&
						checkbox && this.timedAttendance.includes(Number(checkbox.value) )) {
            errMsg += lang.diary_js_message_attendance_time_missing + '<br />'
            break
          }
        }
      }

      for (const thisel of this.el.attributesInputs) {
        switch (thisel.type) {
          case 'hidden':
            if (thisel.hasAttribute('data-ff-external_id')) {
              delete unanswered[Manipulator.getDataAttribute(thisel, ('external_id'))]
            }

            break
          case 'select':
          case 'select-one':
            if (thisel.selectedIndex > 0) {
              delete unanswered[thisel.id]
            }

            break
          case 'radio':
          case 'checkbox':
          default:
            if (thisel.checked == true) {
              if (thisel.hasAttribute('data-ff-external_id')) {
                delete unanswered[Manipulator.getDataAttribute(thisel, ('external_id'))]
              } else if (thisel.hasAttribute('questionData')) {
                try {
                  const params = JSON.parse(thisel.hasAttribute('questionData'))
                  delete unanswered[params.external_id]
                // eslint-disable-next-line no-unused-vars
                } catch (_error) {}
              } else {
                delete unanswered[thisel.id]
              }

              break
            }
        }
      }

      if (Object.keys(unanswered).length) {
        // eslint-disable-next-line guard-for-in
        for (const i in unanswered) {
          if (isMobile) {
            const firstThisInp = document.querySelector('[data-ff-external_id="' + i + '"]')
            if (firstThisInp !== null) {
              const panelPage = SelectorEngine.parents(firstThisInp, 'div.question-group')
              if (panelPage.length > 0 &&
                 !panelPage[0].classList.contains('hidden') &&
                 unanswered[i] && unanswered[i].description !== undefined) {
                errMsg += lang.diary_js_message_complete_entries + ' "' + unanswered[i].description + '"<br />'
                break
              }
            } else {console.log(i + ' missing')}
          } else {
            if (unanswered[i] &&
               unanswered[i].description &&
                Object.keys(unanswered[i]).length &&
                 unanswered[i].description !== undefined) {
              errMsg += lang.diary_js_message_complete_entries + ' "' + unanswered[i].description + '"<br />'
            }
          }
        }
      }
    }

    if (errMsg !== '') {
      event.preventDefault()
      Dialog.alert(this.el.form, { content: errMsg })
      return false
    }

    // this.el.form.submit();
    return true
  }
}

export default OccAttributes
