
import {
  getElementFromSelector
} from './util/index'

import Data from './dom/data'
import Dialog from './dialog'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import Help from './help'
import { isMobile } from './util'

const NAME = 'occcourses'
const DATA_KEY = 'ff.occcourses'
const DATA_API_KEY = '.data-api'
const EVENT_KEY = `.${DATA_KEY}`

const EVENT_CHANGE = `change${EVENT_KEY}`
const EVENT_CLICK = `click${EVENT_KEY}`
const EVENT_SUBMIT = `submit${EVENT_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

class OccCourses {
  constructor(config) {
    this.config = config
    this.helpTopic = 'occasion_courses'
    this.courses = config
    this.form = document.getElementById('f1')
    EventHandler.on(this.form, EVENT_SUBMIT, event => {
      return this.validate(event)
    })
    Help.init()
    if (isMobile) {
      // Get the element
      const elem = document.querySelector('.side-days')
      // Create a copy of it
      const clone = elem.cloneNode(true)
      elem.hide()
      clone.classList.add('clone')
      // Inject it into the DOM
      document.querySelector('header.header .nav').after(clone)
    }
  }

  validate(event) {
    let validForm = true
    const invalid_courses = []
    const filled_courses = []
    let nothing_checked = true
    const isObject = typeof event  ==='object' // is the given argument an object
    const isEvent = isObject ? event.hasOwnProperty('target') : false

    for (const course of this.courses) {
      if (!document.getElementById('courses[' + Number.parseInt(course.course_id, 10) + ']').checked) {
        if (course.usage_course_id != null && Number.parseInt(course.usage_course_id, 10) > 0) {
          validForm = false
          filled_courses.push(course.label)
        } else if (Number.parseInt(course.validate, 10) == 1) {
          validForm = false
          invalid_courses.push(course.label)
        }
      } else {
        nothing_checked = false
      }
    }

    if (validForm && !nothing_checked) {
      return true
    }

    if (nothing_checked) {
      this.alert_nothing(isEvent ? event : null)
    } else {
      this.confirm((isEvent ? event : null), filled_courses, invalid_courses)
    }

    event.preventDefault()
    return false
  }

  confirm(ev, filled_courses, invalid_courses) {
    filled_courses = (filled_courses && filled_courses.length > 0) ? ('<br />' + lang.diary_js_filled_courses + '<br />' + filled_courses.join(', ')) : ''
    invalid_courses = (invalid_courses && invalid_courses.length > 0) ? ('<br />' + lang.diary_js_invalid_courses + '<br />' + invalid_courses.join(', ')) : ''
    Dialog.confirm(
      null,
      {
        content: filled_courses + invalid_courses,
        buttons: [
          {
            label: lang.diary_js_cancel || 'Cancel',
            data: { 'ff-dismiss': 'dialog' }
          },
          {
            label: lang.diary_js_ok || 'Ok',
            action: ev => {
              document.getElementById('f1').action += '&confirm_deletion=1'
              document.getElementById('f1').submit()
            }
          }
        ]
      }
    )
  }

  alert_nothing(ev) {
    Dialog.alert(
      null,
      {
        content: lang.diary_js_no_course_selected,
        okLabel: lang.diary_ok_btn || 'ok'
      }
    )
  }

  // Getters
  static get DATA_KEY() {
    return DATA_KEY
  }
}

export default OccCourses
