
import { defineJQueryPlugin } from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import BaseComponent from './base-component'
import { getUID, isMobile } from './util'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'home'
const DATA_KEY = 'ff.home'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const CLASS_NAME_ACTIVE = 'active'

const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Home extends BaseComponent {
  // Getters

  static get DATA_KEY() {
    return DATA_KEY
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  if ((document.getElementById('page-index') || document.getElementById('page-login')) && isMobile) {
    document.querySelector('body').classList.add('mobile')
    const tgtDivs = document.querySelectorAll('.right .desc, .right .diary-title')
    for (const block of tgtDivs) {
      const thisId = getUID(NAME)
      const check = document.createElement('input')
      check.type = 'checkbox'
      check.style.display = 'none'
      check.id = thisId
      check.classList.add('accordion')
      const label = document.createElement('label')
      // eslint-disable-next-line unicorn/prefer-dom-node-text-content
      label.textContent = block.querySelector('h2').innerText
      label.setAttribute('for', thisId)
      label.classList.add('caret')
      block.querySelector('h2').replaceChildren(label)
      const thisH2 = block.querySelector('h2')
      if (thisH2.classList.contains('sub-title')) {
        block.parentElement.insertBefore(check, block)
      } else {
        thisH2.parentElement.insertBefore(check, thisH2)
      }
    }
  }
})

export default Home
