
import { defineJQueryPlugin } from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import BaseComponent from './base-component'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'lightmode'
const DATA_KEY = 'ff.lightmode'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const CLASS_NAME_ACTIVE = 'active'

const EVENT_LOAD_DATA_API = `load${EVENT_KEY}${DATA_API_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const SELECTOR_DATA_TOGGLE = '[data-ff-toggle="lightmode"]'

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Lightmode extends BaseComponent {
  // Getters

  static get DATA_KEY() {
    return DATA_KEY
  }

  toggle() {
    // Toggle class and sync the `aria-pressed` attribute with the return value of the `.toggle()` method
    const elems = document.querySelectorAll(SELECTOR_DATA_TOGGLE);
    [].forEach.call(elems, el => {
      el.removeAttribute('aria-pressed')
      el.classList.remove(CLASS_NAME_ACTIVE)
    })
    this._element.setAttribute('aria-pressed', this._element.classList.toggle(CLASS_NAME_ACTIVE))
    if (this._element.classList.contains('dark-mode')) {
      Lightmode.darkMode()
    } else {
      Lightmode.lightMode()
    }
  }

  // Static

  static lightMode() {
    document.body.classList.remove('lightmode', 'darkmode')
    document.body.classList.add('lightmode')
    localStorage.setItem('mode', 'light')
  }

  static darkMode() {
    document.body.classList.remove('lightmode', 'darkmode')
    document.body.classList.add('darkmode')
    localStorage.setItem('mode', 'dark')
  }

  static jQueryInterface(config) {
    return this.each(function () {
      let data = Data.getData(this, DATA_KEY)

      if (!data) {
        data = new Lightmode(this)
      }

      if (config === 'toggle') {
        data[config]()
      }
    })
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, event => {
  event.preventDefault()

  const button = event.target.closest(SELECTOR_DATA_TOGGLE)

  let data = Data.getData(button, DATA_KEY)
  if (!data) {
    data = new Lightmode(button)
  }

  data.toggle()
})

EventHandler.on(window, EVENT_LOAD_DATA_API, () => {
  const mode = localStorage.getItem('mode')

  if (mode === 'dark') {
    Lightmode.darkMode()
  } else {
    Lightmode.lightMode()
  }
})

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Lightmode to jQuery only if jQuery is present
 */

defineJQueryPlugin(NAME, Lightmode)

export default Lightmode
