
import EventHandler from './dom/event-handler'

const NAME = 'api'
const DATA_KEY = 'ff.api'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const EVENT_AJAXSEND = `ajaxSend${EVENT_KEY}${DATA_API_KEY}`
const EVENT_AJAXCOMPLETE = `ajaxComplete${EVENT_KEY}${DATA_API_KEY}`

const fetch = (method, url, body = null, formdata = false) => {
  const headers = {
    method,
    cache: 'reload', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': (formdata) ? 'application/x-www-form-urlencoded' : 'application/json'
    }
  }
  if (method === 'POST' || method === 'PUT') {
    headers.body = body
  }

  EventHandler.trigger(document, EVENT_AJAXSEND)
  return window.fetch(url, headers)
    .then(response => {
      const contentType = response.headers.get('content-type')

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json()
      } else {
        return response.text()
      }
    })
    .then(data => {
      EventHandler.trigger(document, EVENT_AJAXCOMPLETE)
      //const bodyData = (formdata) ? data.text() : data.json()

      if (typeof data === 'string' && (
        data.includes('<pre') || 
        data.includes('reload') || 
        data.includes('Exception'))) {
        window.location.reload()
      }

      return data
    })
    .catch(() => {
      window.location.reload();
    })
}

class api {
  static get(url, data, formdata) {
    const esc = encodeURIComponent
    const query = Object.keys(data)
        .map(k => esc(k) + '=' + esc(data[k]))
        .join('&')
    if (query) {
      url += (url.includes('?')) ? '&' + query : '?' + query
    }

    return fetch('GET', url, null, formdata)
  }

  static post(url, data, formdata) {
    let body = {}
    if (formdata) {
      const esc = encodeURIComponent
      body = Object.keys(data)
                .map(k => esc(k) + '=' + esc(data[k]))
                .join('&')
    } else {
      body = JSON.stringify(data)
    }

    return fetch('POST', url, body, formdata)
  }

  static delete(url) {
    return fetch('DELETE', url)
  }

  static put(url, data) {
    const body = JSON.stringify(data)
    return fetch('PUT', url, body)
  }
}

EventHandler.on(document, EVENT_AJAXSEND, () => {
  document.body.style.cursor = 'progress'
  document.getElementById('ajaxoverlayer').show()
})

EventHandler.on(document, EVENT_AJAXCOMPLETE, () => {
  document.body.style.cursor = ''
  document.getElementById('ajaxoverlayer').hide()
})

export default api
