import Dialog from './dialog'
import Help from './help'
import { isMobile } from './util'

const NAME = 'dayoverfview'
const DATA_KEY = 'ff.dayoverfview'
const EVENT_KEY = `.${DATA_KEY}`

const Default = {
  attributes: {
    values: {
      ate: 1,
      lunchbox: 2,
      not: 3,
      out: 4
    }
  }
}

class DayOverview {
  constructor(config) {
    this.helpTopic = 'diary_overview'
    this.config = config
    Help.init({ topic: this.helpTopic })
    if (isMobile && document.querySelectorAll('.days[role="tablist]').length === 0) {
      document.getElementById('occasions').classList.add('tab-clicked')
    }
  }

  // Getters

  static get Default() {
    return Default
  }

  static create(options) {
    return new DayOverview(options)
  }

  static complete_day(ev, completeDay) {
    const url = new URL(window.location.href)
    const SES = url.searchParams.get('SES') ? url.searchParams.get('SES') : ''

    // Dialog.confirm(null,{
    Dialog.confirm(ev, {
      extraClasses: 'modal-lg',
      content: {
        url: ff.pages.ajax,
        options: {
          parameters: {
            act: 'complete_day',
            date: completeDay,
            SES
          }
        }
      },
      buttons: [
        {
          label: lang.diary_js_cancel || 'Cancel',
          data: { 'ff-dismiss': 'dialog' }
        },
        {
          label: lang.diary_js_ok || 'Ok',
          action: ev => {
            DayOverview.submit(ev)
          }
        }
      ]
    })
  }

  static complete_week(ev) {
    Dialog.confirm(ev,
      {
        content: lang.diary_js_complete_week_confirm,
        btnOKLabel: lang.diary_js_ok || 'OK',
        btnOKClass: null,
        btnOKCallback: () => {
          document.getElementById('complete_week').submit()
        }
      })
  }

  static submit(win) {
    const confirm_form = document.querySelector('form#confirm')
    let valid = true
    const inputs = confirm_form.querySelectorAll('input[type=radio]')
    let clicked = true
    let current = ''

    for (let i = 0; i < inputs.length && valid; i++) {
      if (current != inputs[i].name) {
        if (!clicked) {
          valid = false
        }

        clicked = false
        current = inputs[i].name
      }

      if (inputs[i].checked) {
        clicked = true
        console.log(i + ' ' + inputs[i].value + ' ' + Default.attributes.values.ate + ' ' + Default.attributes.values.lunchbox)
        if (inputs[i].value == Default.attributes.values.ate || inputs[i].value == Default.attributes.values.lunchbox) {
          valid = false
        }
      }
    }

    if (valid) {
      confirm_form.submit()
    } else {
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
      // If cancelled, don't dispatch our event
      const canceled = !win.querySelector('[data-ff-dismiss="dialog"]').dispatchEvent(evt)
      // Dialog.alert(document.querySelector('.tab-pane.active'), {content: lang['diary_js_invalid_complete_day']});
      Dialog.alert(evt, { content: lang.diary_js_invalid_complete_day })
    }

    return valid
  }
}

export default DayOverview
